<template>
  <div class="animation-test-wrapper">
    <div class="animation-text-wrapper">
      <h1 class="animation-text"></h1>
      <h1 class="animation-text">this is some test text</h1>
      <h1 class="animation-text">xd</h1>
      <h1 class="animation-text animation-text-last">get real</h1>
    </div>
    <div style="position: fixed; top: 120px; left: 0px; width: 50%; z-index: 5;" v-if="elements">
      <div v-for="el in elements" :key="el"> 
        {{ el.debug }}
      </div>
    </div>
  </div>
</template>

<script>
import * as THREE from 'three';
import { OBJLoader } from 'three/addons/loaders/OBJLoader.js';
import { MTLLoader } from 'three/addons/loaders/MTLLoader.js';

const _ = require('lodash');

export default {
  name: 'AnimationTestView',
  data () {
    return {
      scrollPercent: 0,
      elements: null
    }
  },
  methods: {
    handleScroll () {
      const scrollTop = window.scrollY || this.$el.scrollTop;
      const docHeight = document.documentElement.scrollHeight - document.documentElement.clientHeight;
      this.scrollPercent = scrollTop / docHeight;
    },
    init () {
      const aspect = window.innerWidth / window.innerHeight;
      const fSize = 15;

      const loader = new OBJLoader();
      const mtlloader = new MTLLoader();

      const scene = new THREE.Scene();
      const camera = new THREE.OrthographicCamera(fSize * aspect / - 2, fSize * aspect / 2, fSize / 2, fSize / - 2, 1, 100);

      const al = new THREE.AmbientLight(0xffffff, 1);
      scene.add(al);

      const dl = new THREE.DirectionalLight(0xffffff, 1.75);
      scene.add(dl);

      // load pcunit obj 3 times
      const elements = [];

      for(let i = 0; i < 3; i++) {
        mtlloader.load('/models/pcunit/unit1.mtl', mat => {
          mat.preload();

          loader.setMaterials(mat);
          loader.load('/models/pcunit/unit1.obj', obj => {
              obj.scale.set(.035, .035, .035);
  
              elements.push(obj);
  
              // initialize animation data
              obj.anim = {};
              obj.anim.currentPos = [0, 0, 0];
              obj.anim.currentRot = [0, 0, 0];
  
              scene.add(obj);
  
              if (i == 0) { // set animation path for first unit
                obj.anim.path = {
                  0: [-4, 0, 1],
                  .33: [-10, -20, 0],
                  .66: [-10, -10, 0],
                  1: [-10, 0, 0]
                }
  
                obj.anim.rot = {
                  0: [0, 0, 0],
                  .33: [0, 35, 0]
                }

                obj.anim.path = {
                  0: [-4, 0, 1],
                  .33: [-20, -10, 0],
                  .66: [-20, -10, 0],
                  1: [-10, 0, 0]
                }

                obj.anim.rot = {
                  0: [0, 0, 0],
                  .33: [0, 0, 0],
                  .66: [0, 0, -90],
                  1: [0, 0, 0]
                }
              } else if (i == 1) { // set animation path for second unit
                obj.anim.path = {
                  0: [4, 0, 1],
                  .33: [-10, 0, 0],
                  .66: [-10, 10, 0],
                  1: [-10, 20, 0]
                }
  
                obj.anim.rot = {
                  0: [0, 0, 0],
                  .33: [0, 35, 0]
                }

                obj.anim.path = {
                  0: [4, 0, 1],
                  .33: [-10, 0, 0],
                  .66: [-20, 10, 0],
                  1: [-20, 10, 0]
                }

                obj.anim.rot = {
                  0: [0, 0, 0],
                  .33: [0, 0, 0],
                  .66: [0, 0, 90],
                  1: [0, 0, 90]
                }
              } else if (i == 2) { // set animation path for third unit
                obj.anim.path = {
                  0: [0, 2, 0],
                  .33: [-10, -10, 0],
                  .66: [-10, 0, 0],
                  1: [-10, 10, 0]
                }
  
                obj.anim.rot = {
                  0: [0, 0, 0],
                  .33: [0, 35, 0]
                }

                obj.anim.path = {
                  0: [0, 2, 0],
                  .33: [-20, -10, 0],
                  .66: [-10, 0, 0],
                  1: [-20, 10, 0]
                }

                obj.anim.rot = {
                  0: [0, 0, 0],
                  .32: [0, 0, 0],
                  .33: [0, 0, -90],
                  .66: [0, 0, 0],
                  1: [0, 0, 90]
                }
              }
            });
        });
      }

      // debug and animation testing geometry
      // const geo = new THREE.BoxGeometry(6, 4, .1);
      // const mat = new THREE.MeshBasicMaterial({color: 0x0ffff0});

      // const box1 = new THREE.LineSegments(geo, mat);
      // const box2 = new THREE.LineSegments(geo, mat);
      // const box3 = new THREE.LineSegments(geo, mat);

      camera.position.z = 5;

      dl.position.z = 5;
      // dl.position.y = 10;
      dl.rotation.x = -90;

      /**
       * scroll animation params
       */

      elements.forEach(el => {
        el.anim = {};
        el.anim.currentPos = [0, 0, 0];
        el.anim.currentRot = [0, 0, 0];
      });

      const renderer = new THREE.WebGLRenderer({alpha: true});
      renderer.setPixelRatio(window.devicePixelRatio);
      renderer.setSize(window.innerWidth, window.innerHeight);
      renderer.autoClear = true;

      const animate = () => {
        this.handleAnimation(elements, this.scrollPercent);
        this.elements = [...elements];        

        elements.forEach(el => {
          el.position.x = el.currentPos[0];
          el.position.y = el.currentPos[1];
          el.position.z = el.currentPos[2];

          el.rotation.x = el.currentRot[0] * (Math.PI / 180);
          el.rotation.y = el.currentRot[1] * (Math.PI / 180);
          el.rotation.z = el.currentRot[2] * (Math.PI / 180);
        });

        renderer.render(scene, camera);
      }
      renderer.setAnimationLoop(animate);

      this.$el.appendChild(renderer.domElement);
    },
    handleAnimation (elements, percent) {
      elements.forEach(el => {
        // position vars
        let pathKeys = _.sortBy(Object.keys(el.anim.path));
        let numKeys = pathKeys.length - 1;
        let currentKey = 0;
        

        while (numKeys > currentKey && percent > parseFloat(pathKeys[currentKey + 1])) currentKey++;
        
        let nextKey = numKeys > currentKey ? currentKey + 1 : currentKey;

        let startingPos = el.anim.path[pathKeys[currentKey]];
        let endingPos = el.anim.path[pathKeys[nextKey]];

        // rotation vars
        let rotKeys = _.sortBy(Object.keys(el.anim.rot));
        let numRotKeys = rotKeys.length - 1;
        let currentRotKey = 0;
        
        while (numRotKeys > currentRotKey && percent > rotKeys[currentRotKey + 1]) currentRotKey++;
        
        let nextRotKey = numRotKeys > currentRotKey ? currentRotKey + 1 : currentRotKey;

        let startingRot = el.anim.rot[rotKeys[currentRotKey]];
        let endingRot = el.anim.rot[rotKeys[nextRotKey]];

        // interpolate animation steps
        let p = (percent - pathKeys[currentKey]) / (pathKeys[nextKey] - pathKeys[currentKey]);
        let pos = endingPos.map((num, index) => startingPos[index] + ((num - startingPos[index]) * p));

        let r = (percent - rotKeys[currentRotKey]) / (rotKeys[nextRotKey] - rotKeys[currentRotKey]);
        let rot = endingRot.map((num, index) => startingRot[index] + ((num - startingRot[index]) * r));

        pos.forEach((axis, index) => {
          if (startingPos[index] == endingPos[index]) pos[index] = endingPos[index];
        });

        rot.forEach((axis, index) => {
          if (startingRot[index] == endingRot[index]) rot[index] = endingRot[index];
        });

        el.currentPos = pos;
        el.currentRot = rot;

        el.debug = {
          percent: percent,
          path: {
            raw: el.anim.path,
            pathKeys: pathKeys,
            numKeys: numKeys,
            currentKey: currentKey,
            p: p,
            pos: pos
          },
          rotation: {
            raw: el.anim.rot,
            rotKeys: rotKeys,
            numRotKeys: numRotKeys,
            currentRotKey: currentRotKey,
            r: r,
            rot: rot
          },
          test1: numKeys > currentKey,
          test2: percent > parseFloat(pathKeys[currentKey + 1]),
          test3: numKeys > currentKey && percent > parseFloat(pathKeys[currentKey + 1])
        }
      });
    }
  },
  mounted () {
    this.init();

    document.addEventListener('scroll', () => {
      this.handleScroll();
    })
  }
}
</script>

<style>
canvas {
  position: sticky;
  top: 0px;
}

.animation-test-wrapper {
  width: 100vw;
  height: 300vh;
}

.animation-text-wrapper {
  width: 100%;
  height: 300vh;

  position: absolute;

  display: flex;
  flex-direction: column;

  justify-content: space-around;
  align-items: center;
}

.animation-text {
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
}

.animation-text-last {
  padding-bottom: 120px;
}

@media screen and (max-device-width: 1000px) {
  .animation-test-wrapper {
    padding-left: 0%;
  }
}
</style>