<!-- this file contains root styling vars -->
<template>
  <!-- load Inter font -->
  <link rel="preconnect" href="https://fonts.googleapis.com">
  <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
  <div id="app-wrapper">
    <header-section></header-section>
    <router-view />
    <footer-section></footer-section>
  </div>
</template>

<script>
import HeaderSection from './components/HeaderSection.vue';
import FooterSection from './components/FooterSection.vue';

export default {
  name: 'App',
  components: { HeaderSection, FooterSection }
}
</script>

<style>
/* poppins font */
@font-face {
  font-family: 'Poppins';
  src: url('@/assets/Poppins-Regular.ttf');
}

@font-face {
  font-family: 'Poppins Extra Bold';
  src: url('@/assets/Poppins-ExtraBold.ttf');
}


/* global stylings */
* {
  font-family: 'Poppins';
  color: var(--color-primary);
}

h1 {
  font-family: 'Poppins Extra Bold';
}

/* css vars for components and pages */
:root {
  --header-height: 126.77px;
  --header-image-height: 29px;

  --color-primary: #06193f;
  --color-secondary: #09d3b1;
  --color-tertiary: #398aff;
}

/* app stylings */
body {
  background-color: var(--color-tertiary);
}

#app-wrapper {
  max-width: 100vw;
  margin: 0px;
  margin: -8px;
}
</style>