<template>
  <div class="header-wrapper">
    <!-- header image -->
    <div class="header-image-wrapper" @click="nav('/')">
      <img class="header-image" src="@/assets/Primary_Logo.svg" />
    </div>
    <!-- burger menu for mobile -->
    <font-awesome-icon :icon="['fas', 'bars']" class="header-mobile-button" @click="headerMobileOpen = !headerMobileOpen" />
    <!-- buttons -->
    <div :class="{'header-button-wrapper': true, 'header-mobile-open': headerMobileOpen}">
      <nav-button @click="nav('about')">What We Do</nav-button>
      <nav-button @click="nav('setup')">Setup Guide</nav-button>
      <nav-button @click="nav('aboutus')">About Us</nav-button>
      <nav-button @click="nav('documentation')">Documentation</nav-button>
      <nav-button @click="nav('contact')">Contact</nav-button>
      <nav-button type="sunken" @click="redir('https://protocontrol.dev/builder/')" class="mobile">BUILD</nav-button>
    </div>
    <!-- build bubble -->
    <div class="header-build-bubble desktop" @click="redir('https://protocontrol.dev/builder/')">
      <h1>BUILD</h1>
    </div>
  </div>
</template>

<script>
// import vue components
import NavButton from './NavButton.vue';

// fa imports
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { fas } from '@fortawesome/free-solid-svg-icons';
library.add(fas);

export default {
  components: { NavButton, FontAwesomeIcon },
  name: "HeaderSection",
  data () {
    return {
      headerMobileOpen: false
    }
  },
  methods: {
    // navigate vue router
    nav (dest) {
      this.$router.push(dest);
      this.headerMobileOpen = false;
    },
    // navigate window url
    redir (dest) {
      window.location.href = dest;
    }
  },
  mounted () {
    // keyboard shortcut to view temp animation page
    document.addEventListener('keydown', e => {
      if (e.key === '`') this.nav('animation');
    });
  }
}
</script>

<style>
.header-wrapper {
  width: calc(100vw - 260px);
  height: calc(var(--header-height) - 100px);

  border-bottom: 5px solid var(--color-primary);

  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 25px;
  padding-left: 25px;
  padding-right: 25px;
  margin: 25px;
}

.header-button-wrapper {
  display: flex;
}

.header-image {
  height: var(--header-image-height);
  margin: 12px;
}

.header-build-bubble {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  cursor: pointer;
  top: 25px;
  right: 10px;

  background-image: url('@/assets/buildbubble.png');
  background-size: 150px 150px;

  width: 150px;
  height: 150px;
  border-radius: 50%;

  transition: all .2s ease;
}

.header-build-bubble:hover {
  box-shadow: 0px 0px 16px var(--color-secondary);

  transition: all .2s ease;
}

.header-image-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;
}

.header-spacer {
  width: 8px;
}

.header-mobile-button {
  display: none;
}

.mobile {
  display: none !important;
}

/* mobile stylings */
@media screen and (max-device-width: 1000px) {
  .mobile {
    display: flex !important;
  }

  .desktop {
    display: none;
  }

  .header-mobile-button {
    display: block;
    font-size: 2.5rem;
    cursor: pointer;
  }

  .header-mobile-button:hover {
    background-color: rgba(0, 0, 0, .05);
  }

  .header-button-wrapper {
    background-color: var(--color-tertiary);
    flex-direction: column;
    overflow: hidden;

    transition: max-height .2s ease;

    position: absolute;
    top: calc(var(--header-height));    
    left: 0px;
    width: 100vw;
    max-height: 0px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;

    z-index: 2;
  }

  .header-button-wrapper.header-mobile-open {
    max-height: 70vh;
  }

  .header-wrapper {
    width: calc(100vw - 100px);
  }
}
</style>